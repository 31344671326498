var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.albums.length)?_c('div',{staticClass:"swiper-warp"},[_c('div',{staticClass:"title-warp"},[_c('div',{staticClass:"left-icon"}),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n            "+_vm._s(_vm.titleContent)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"collection-warp"},_vm._l((_vm.albums),function(item){return _c('div',{directives:[{name:"log",rawName:"v-log",value:({
                type: 'show',
                name: 'COLLECTION_PHOTO_COVER',
                value: {
                    col_id: item.albumIdStr,
                },
            }),expression:"{\n                type: 'show',\n                name: 'COLLECTION_PHOTO_COVER',\n                value: {\n                    col_id: item.albumIdStr,\n                },\n            }"}],key:item.albumIdStr,staticClass:"item",on:{"click":() => _vm.handleClickAlbum(item)}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.firstPhotoCover[0]?.url),expression:"item.firstPhotoCover[0]?.url"}],staticClass:"img",attrs:{"alt":""}}),_vm._v(" "),_c('div',{staticClass:"top-right-warp"},[_vm._v("\n                "+_vm._s(item.count)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"content-warp"},[_vm._v("\n                "+_vm._s(item.firstPhotoCaption)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"back-icon"}),_vm._v(" "),_c('a',{staticClass:"link",attrs:{"href":`${_vm.origin}/${_vm.kwaiId}/playlist/${item.albumIdStr}`}})])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }