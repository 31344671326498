
import List from 'vant/lib/list';
import { defineComponent, getCurrentInstance, onMounted, ref, PropType, computed, defineAsyncComponent } from 'vue';
import PostCard from '../post-card/index.vue';
import CollectionSwipper from '../collection-swipper/index.vue';

export default defineComponent({
    components: {
        vanList: List,
        PostCard,
        CollectionSwipper,
    },
    props: {
        feeds: {
            type: Array as PropType<Record<string, any>>,
            default: () => [],
        },
        albums: {
            type: Array as PropType<Record<string, any>>,
            default: () => [],
        },
        albumcount: {
            type: Number,
            default: () => 0,
        },
        finished: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const list = ref([]);

        const loadingEnd = computed(() => {
            return proxy.$root.$t('k_344289').replace('${0}', proxy.$appName);
        });

        const loadingProps = computed({
            get: () => props.loading,
            set: (value: boolean) => {
                return ctx.emit('update:loading', value);
            },
        });

        const onLoad = () => {
            ctx.emit('onLoad');
        };

        onMounted(() => {
            // console.log('route', proxy.$route.params.id)
        });

        return {
            proxy,
            list,
            onLoad,
            loadingEnd,
            loadingProps,
        };
    },
});
