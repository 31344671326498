
import Vue, { defineComponent, ref, getCurrentInstance, onMounted, computed } from 'vue';
import { formateNum, getQuery } from '@/common/utils';
import { PwaBridge } from '~/common/bridge';

export default defineComponent({
    components: {},
    props: {
        imgSrc: {
            type: String,
            // required: true,
            default:
                'https://ak-br-pic.kwai.net/upic/2023/05/26/08/BMjAyMzA1MjYwODAzMjhfMTUwMDAwOTEwOTI2Mzg2XzE1MDEwMzAyODAzMDM4NV8yXzM=_low_B8563b5e79148c55aa88fe4ee8c4f1f10.webp?tag=1-1685225783-w-0-vc0qtfqjnu-01bb6110759e0a01',
        },
        likeCount: {
            type: Number,
            required: true,
            default: 0,
        },
        photoId: {
            type: String,
            required: true,
        },
        kwaiId: {
            type: String,
            required: true,
        },
        profileUserTop: {
            type: Boolean,
            required: false,
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: Vue };
        const origin = ref('');
        const query = getQuery();

        const jumpLink = computed(() => {
            return `/@${props.kwaiId}/video/${props.photoId}`;
        });

        const handleClick = async () => {
            const url = `${window?.location?.origin}${jumpLink.value}${window?.location?.search}`;

            if (proxy?.$isAppEnvironment) {
                PwaBridge.openWebview({
                    url,
                });
                return;
            }

            window.open(url, '_blank');
        };

        onMounted(() => {
            origin.value = window.location.origin;
        });

        return {
            origin,
            proxy,
            formateNum,
            handleClick,
            jumpLink,
        };
    },
});
