
import { defineComponent, getCurrentInstance, PropType, onMounted, computed, ref } from 'vue';
import { getQuery, getUrlKwaiId } from '@/common/utils';
import { sendLog } from '@/common/radar';
import { PwaBridge } from '@/common/bridge';

export default defineComponent({
    components: {},
    props: {
        albums: {
            type: Array as PropType<Record<string, any>>,
            default: () => [],
        },
        albumcount: {
            type: Number,
            default: () => 0,
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const origin = ref('');
        const kwaiId: any = ref('');
        const query = getQuery();

        const titleContent = computed(() => {
            return proxy.$root.$t('k_407166').replace('${0}', props.albumcount);
        });

        const handleClickAlbum = (item: any) => {
            sendLog({
                type: 'click',
                name: 'COLLECTION_PHOTO_COVER',
                value: {
                    col_id: item.albumIdStr,
                },
            });

            const url = `${window?.location?.origin}/${kwaiId.value}/playlist/${item.albumIdStr}${window?.location?.search}`;

            if (proxy?.$isAppEnvironment) {
                PwaBridge.openWebview({
                    url,
                });
                return;
            }

            window.open(url, '_blank');
        };
        onMounted(() => {
            origin.value = window.location.origin;
            kwaiId.value = getUrlKwaiId();
        });
        return {
            origin,
            kwaiId,
            titleContent,
            handleClickAlbum,
        };
    },
});
