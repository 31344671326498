// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/like.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card[data-v-d088e9a6] {\n  width: 33.33%;\n  height: 44.4444vw;\n  border-right: 1px solid #fff;\n  border-bottom: 1px solid #fff;\n  position: relative;\n}\n.card .top[data-v-d088e9a6] {\n  padding: 0.4831vw 0.9662vw;\n  background: #fff;\n  font-weight: 500;\n  font-size: 2.1739vw;\n  color: #1f1f1f;\n  position: absolute;\n  top: 1.2077vw;\n  left: 1.2077vw;\n  border-radius: 0.7246vw;\n}\n.card .img[data-v-d088e9a6] {\n  height: 100%;\n  width: 100%;\n  -o-object-fit: cover;\n     object-fit: cover;\n  background-repeat: no-repeat;\n  background-position: center center;\n  background-size: cover;\n  background-color: rgba(0,0,0,0.1);\n  pointer-events: none;\n}\n.card .link[data-v-d088e9a6] {\n  pointer-events: none;\n  height: 100%;\n}\n.card .like-warp[data-v-d088e9a6] {\n  height: 5.7971vw;\n  position: absolute;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n}\n.card .like-warp .like-count[data-v-d088e9a6] {\n  height: 5.7971vw;\n  display: flex;\n  align-items: center;\n  font-weight: 400;\n  font-size: 2.8986vw;\n  color: #fff;\n}\n.card .like-warp .like-icon[data-v-d088e9a6] {\n  height: 5.7971vw;\n  width: 5.7971vw;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/100% no-repeat;\n}\n@media screen and (min-width: 750px) {\n.card[data-v-d088e9a6] {\n    height: 350px;\n}\n.card .top[data-v-d088e9a6] {\n    padding: 2px 4px;\n    font-size: 9px;\n    top: 5px;\n    left: 5px;\n    border-radius: 3px;\n}\n.card .like-warp[data-v-d088e9a6] {\n    height: 24px;\n}\n.card .like-warp .like-count[data-v-d088e9a6] {\n    height: 24px;\n    font-size: 12px;\n}\n.card .like-warp .like-icon[data-v-d088e9a6] {\n    height: 24px;\n    width: 24px;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
